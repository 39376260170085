import React, { useState, useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import axios from 'axios';
import config from 'src/config';
import Results from './Results';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const CustomerListView = () => {
  const classes = useStyles();
  const [entries, setEntry] = useState([]);

  useEffect(() => {
    // code to run on component mount
    axios.get(`${config.API_URL}/apis/enter/`).then((response) => {
      setEntry(response.data.results);
      console.log(response.data.results);
    });
  }, []);

  return (
    <Page className={classes.root} title="Registrations | Dashboard">
      <Container maxWidth={false}>
        <Toolbar />
        <Box mt={3}>
          <Results customers={entries} />
        </Box>
      </Container>
    </Page>
  );
};

export default CustomerListView;
