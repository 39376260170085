import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AccountView from 'src/views/account/AccountView';
import CustomerListView from 'src/views/customer/CustomerListView';
import DashboardView from 'src/views/reports/DashboardView';
import LoginView from 'src/views/auth/LoginView';
import NotFoundView from 'src/views/errors/NotFoundView';
import ProductListView from 'src/views/product/ProductListView';
import RegisterView from 'src/views/auth/RegisterView';
import SettingsView from 'src/views/settings/SettingsView';
import CrowdAfrikBonanza from 'src/views/product/CrowdAfrikBonanza';
import FlashMeCash from 'src/views/product/FlashMeCash';
import BizzyMoni from 'src/views/product/BizzyMoni';
import FarmersMoni from 'src/views/product/FarmersMoni';
// import CrowdAfrikAfricaBonanza from 'src/views/product/CrowdAfrikAfricaBonanza';
import CrowdAfrikEdufund from 'src/views/product/CrowdAfrikEdufund';
import TradersMoni from 'src/views/product/TradersMoni';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <AccountView /> },
      { path: 'customers', element: <CustomerListView /> },
      { path: 'dashboard', element: <DashboardView /> },
      { path: 'products', element: <ProductListView /> },
      { path: 'settings', element: <SettingsView /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginView /> },
      { path: 'register', element: <RegisterView /> },
      { path: '404', element: <NotFoundView /> },
      { path: '/', element: <ProductListView /> },
      { path: '/dashboard', element: <Navigate to="/app/customers" /> },
      { path: '/lotto', element: <CrowdAfrikBonanza /> },
      { path: '/CrowdAfrikBonanza', element: <CrowdAfrikBonanza /> },
      { path: '/FlashMeCash', element: <FlashMeCash /> },
      { path: '/BizzyMoni', element: <BizzyMoni /> },
      { path: '/FarmersMoni', element: <FarmersMoni /> },
      { path: '/CrowdAfrikEdufund', element: <CrowdAfrikEdufund /> },
      { path: '/TradersMoni', element: <TradersMoni /> },

      // {
      //   path: '/CrowdAfrikAfricaBonanza',
      //   element: <CrowdAfrikAfricaBonanza />
      // },
      {
        path: '/CrowdAfrikAfricaBonanza',
        element: <CrowdAfrikBonanza />
      },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
