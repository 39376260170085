import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import getInitials from 'src/utils/getInitials';
import styled from 'styled-components';
import theme from 'src/theme';

export const Round = styled.span`
    position: relative;
    display: inline-block;
    border-radius: 50%;
    margin: 2px;
    /* cursor: pointer; */
    border: 1px solid ${theme.palette.primary.main} ;
    text-align: center;
    outline: none;
    height: 30px;
    width: 30px;
    line-height: 33px;
    color: #000080;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 12px;
    flex: 1 100%;
    user-select: none;
    box-sizing: border-box;
    animation: .4s ease-in-out both text;
    -webkit-tap-highlight-color: transparent;
}`;

const useStyles = makeStyles((Theme) => ({
  root: {},
  avatar: {
    marginRight: Theme.spacing(2)
  }
}));

const Results = ({ className, customers, ...rest }) => {
  const classes = useStyles();
  const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);

  const [page, setPage] = useState(0);

  const handleSelectAll = (event) => {
    let newSelectedCustomerIds;

    if (event.target.checked) {
      newSelectedCustomerIds = customers.map((customer) => customer?.id);
    } else {
      newSelectedCustomerIds = [];
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedCustomerIds.indexOf(id);
    let newSelectedCustomerIds = [];

    if (selectedIndex === -1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds,
        id
      );
    } else if (selectedIndex === 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(1)
      );
    } else if (selectedIndex === selectedCustomerIds.length - 1) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCustomerIds = newSelectedCustomerIds.concat(
        selectedCustomerIds.slice(0, selectedIndex),
        selectedCustomerIds.slice(selectedIndex + 1)
      );
    }

    setSelectedCustomerIds(newSelectedCustomerIds);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedCustomerIds.length === customers.length}
                    color="primary"
                    indeterminate={
                      selectedCustomerIds.length > 0
                      && selectedCustomerIds.length < customers.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Country</TableCell>

                <TableCell>Phone</TableCell>
                <TableCell>Selection</TableCell>
                <TableCell>Game Start </TableCell>
                <TableCell>Game End</TableCell>

                <TableCell>Registration Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customers
                .slice(
                  page * limit,
                  customers?.length < (page + 1) * limit
                    ? customers?.length
                    : (page + 1) * limit
                )
                .map((customer) => (
                  <TableRow
                    hover
                    key={customer?.id}
                    selected={selectedCustomerIds?.indexOf(customer?.id) !== -1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={
                          selectedCustomerIds?.indexOf(customer?.id) !== -1
                        }
                        onChange={(event) => handleSelectOne(event, customer?.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <Box alignItems="center" display="flex">
                        <Avatar
                          className={classes.avatar}
                          // src={customer?.avatarUrl}
                        >
                          {getInitials(customer?.name)}
                        </Avatar>
                        <Typography color="textPrimary" variant="body1">
                          {customer?.name}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{customer?.email}</TableCell>
                    <TableCell>{customer?.country}</TableCell>
                    <TableCell>{customer?.mobile_number}</TableCell>
                    <TableCell>
                      {customer?.entry?.split(',')?.map((e) => {
                        return (
                          <div style={{ display: 'inline-block' }}>
                            <Round
                              style={{
                                backgroundColor: 'inherit',
                                color: theme.palette.text.secondary,
                                borderColor: theme.palette.text.secondary
                              }}
                              key={e}
                            >
                              {e}
                            </Round>
                          </div>
                        );
                      })}
                    </TableCell>
                    <TableCell>
                      {moment(customer?.game_start).format(
                        'dddd, MMMM Do YYYY'
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(customer?.game_end).format('dddd, MMMM Do YYYY')}
                    </TableCell>
                    <TableCell>
                      {moment(customer?.timestamp).format(
                        'dddd, MMMM Do YYYY, h:mm:ss a'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={customers?.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  customers: PropTypes.array.isRequired
};

export default Results;
