import { v4 as uuid } from 'uuid';

export default [
  {
    id: uuid(),
    createdAt: '27/12/2020',
    description:
      'The weekly bonanza that can make you a millionaire. Weekly amounts are announced at the start of the week and match 6 numbers out of 60 to win the jackpot or match 4 or 5 of the numbers to win complementary amounts.',
    media: '/static/logo.svg',
    title: 'CrowdAfrik Bonanza',
    totalDownloads: '999',
    url: '/CrowdAfrikBonanza'
  },
  {
    id: uuid(),
    createdAt: '27/12/2020',
    description:
      'We are aware that at times some Africans may not have any money to even recharge credits or data as well as afford the minimal basic need hence CrowdAfrik introduced "Flash me cash". Successful applicants can get in an instant payment any amounts from $5 to $100 in local currency or 5 times to 100 times the application cost.',
    media: '/static/logo.svg',
    title: 'Flash Me Cash',
    totalDownloads: '999',
    url: '/FlashMeCash'
  },
  {
    id: uuid(),
    createdAt: '27/12/2020',
    description:
      "Farmers are vital labour for CrowdAfrik African projects. The raw materials required to run the factories, the raw materials extracted from African abundant natural resources require a bit of capital inputs. The Farmers Moni is the solution designed for farmers who plan to increase their yield. Apply for Farmer's moni and receive instant approval or rejection. Offers range from $100 to $1,000.00 in local currencies.",
    media: '/static/logo.svg',
    title: 'Farmers\' Moni',
    totalDownloads: '999',
    url: '/FarmersMoni'
  },
  {
    id: uuid(),
    createdAt: '27/12/2020',
    description:
      'Are you a business owner, trader, middleman, transporter, or contractor, CrowdAfrik Bizzy Moni can increase your working capital? Get between $1,000 to $100,000 to help expand your medium-sized business.',
    media: '/static/logo.svg',
    title: 'Bizzy Moni',
    totalDownloads: '999',
    url: '/BizzyMoni'
  },
  {
    id: uuid(),
    createdAt: '27/12/2020',
    description:
      'Apply for master’s degree scholarship in UK, USA or Canada. Try CrowdAfrik Edufund (Education Fund) and if selected your full tuition and upkeep will be provided to you. For participating Universities and Courses visit our website.',
    media: '/static/logo.svg',
    title: 'CrowdAfrik Edufund',
    totalDownloads: '999',
    url: '/CrowdAfrikEdufund'
  },
  {
    id: uuid(),
    createdAt: '27/12/2020',
    description:
      'The CrowdAfrik national bonanza is aimed at creating instant millionaires in Africa on a weekly basis. To qualify, applicants within a participating country apply by selecting 6 numbers out of 60 and if their chosen numbers match the announced number, player becomes instant millionaire. Complementary prizes go to matched 5 or 4 numbers.',
    media: '/static/logo.svg',
    title: 'CrowdAfrik Africa Bonanza',
    totalDownloads: '999',
    url: '/CrowdAfrikAfricaBonanza'
  },
  {
    id: uuid(),
    createdAt: '27/12/2020',
    description:
      'You want to expand your business? Win between $200 to $10,000, then set up a corner shop, increase your stocks or venture into new business. Traders Moni offers interest-free funds to meet Africans business.',
    media: '/static/logo.svg',
    title: 'Traders Moni',
    totalDownloads: '999',
    url: '/TradersMoni'
  }
];
