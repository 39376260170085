import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  // Button,
  Container,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import CircularProgress from '@material-ui/core/CircularProgress';

const QRCode = require('qrcode.react');

const useStyles = makeStyles((custTheme) => ({
  root: {
    backgroundColor: custTheme.palette.background.dark,
    height: '100%',
    paddingBottom: custTheme.spacing(3),
    paddingTop: custTheme.spacing(3)
  }
}));

const QRCodeGenerator = (props) => {
  const classes = useStyles();
  const { responseID, isLoading } = props;

  return (
    <Page className={classes.root} title="Enter your details | CrowdAfrik Investments">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Typography color="textPrimary" variant="h3">
              Your entry has been submitted!
            </Typography>
          </Box>
          <Box mb={3}>
            {isLoading ? (
              // TODO remove center tag- bad code
              <center>
                <CircularProgress />
              </center>
            ) : (
              <Box>
                <Box mb={3}>
                  <Typography color="textSecondary" variant="h5">
                    Please screenshot this QR code below or note down the
                    reference number shown below.
                  </Typography>
                  <Typography color="textPrimary" variant="h5">
                    Reference Number :
                    {' '}
                    {responseID}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box mb={2}>
            <center>
              <QRCode size={256} value={responseID} />
            </center>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};
QRCodeGenerator.propTypes = {
  responseID: PropTypes.string,
  isLoading: PropTypes.bool
};
export default QRCodeGenerator;
