import React from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';
// import FacebookIcon from 'src/icons/Facebook';
// import GoogleIcon from 'src/icons/Google';
import theme from 'src/theme';
import Page from 'src/components/Page';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { countriesArray } from './EnterDetailsView';

export const Round = styled.span`
    position: relative;
    display: inline-block;
    border-radius: 50%;
    margin: 2px;
    /* cursor: pointer; */
    border: 1px solid ${theme.palette.primary.main} ;
    text-align: center;
    outline: none;
    height: 60px;
    width: 60px;
    line-height: 63px;
    color: #000080;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 22px;
    flex: 1 100%;
    user-select: none;
    box-sizing: border-box;
    animation: .4s ease-in-out both text;
    -webkit-tap-highlight-color: transparent;
}`;

const useStyles = makeStyles((custTheme) => ({
  root: {
    backgroundColor: custTheme.palette.background.dark,
    height: '100%',
    paddingBottom: custTheme.spacing(3),
    paddingTop: custTheme.spacing(3)
  }
}));

const PreviewEntry = (props) => {
  const classes = useStyles();
  const {
    handleSubmit, handleBack, selectedNumbers, details
  } = props;

  return (
    <Page
      className={classes.root}
      title="Enter your details | CrowdAfrik Investments"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Typography color="textPrimary" variant="h3">
              Preview
            </Typography>
          </Box>
          <Box>
            <Box>
              <Typography color="textPrimary" variant="h6">
                Selected numbers
              </Typography>
            </Box>
            {selectedNumbers.map((e) => {
              return (
                <div style={{ display: 'inline-block' }}>
                  <Round
                    style={{
                      // borderColor: errorSelection ? 'grey' : 'inherit'
                      backgroundColor: 'inherit',
                      color: theme.palette.text.secondary,
                      borderColor: theme.palette.text.secondary
                    }}
                    key={e}
                    // onClick={() => this.onClickNumber(e)}
                  >
                    {e}
                  </Round>
                </div>
              );
            })}
          </Box>
          <Box mb={3}>
            <Box>
              <Typography color="textPrimary" variant="h6">
                Entered details
              </Typography>
            </Box>
            <TextField
              // error={Boolean(touched.email && errors.email)}
              fullWidth
              // helperText={touched.email && errors.email}
              label="Name"
              margin="normal"
              disabled
              value={details?.name}
              // onChange={(e) => setName(e.target.value)}
              type="name"
              variant="outlined"
            />
            <TextField
              // error={Boolean(touched.email && errors.email)}
              fullWidth
              // helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              // onBlur={handleBlur}
              // onChange={(e) => setEmail(e.target.value)}
              type="email"
              disabled
              value={details?.email}
              variant="outlined"
            />
            <TextField
              // error={Boolean(touched.password && errors.password)}
              fullWidth
              // helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              // onBlur={handleBlur}
              disabled
              value={details?.password}
              // onChange={(e) => setPassword(e.target.value)}
              type="password"
              variant="outlined"
            />
            <TextField
              // error={Boolean(touched.password && errors.password)}
              fullWidth
              // helperText={touched.password && errors.password}
              label="Telephone"
              margin="normal"
              name="tel"
              // onBlur={handleBlur}
              disabled
              value={details?.tel}
              // onChange={(e) => setTel(e.target.value)}
              type="text"
              variant="outlined"
            />
            <Box>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    disabled
                    id="date-picker-dialog"
                    label="Choose the start date"
                    format="MM/dd/yyyy"
                    value={details?.selectedStartDate}
                    inputVariant="outlined"
                    // onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        disabled
                        checked={details?.isRepeating}
                        // onChange={() => {
                        //   setRepeat(!isRepeating);
                        // }}
                        name="checkedBox"
                        color={theme.palette.primary.main}
                      />
                    )}
                    label="Repeat these until custom weeks"
                  />
                  {details?.isRepeating && (
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      disabled
                      label="Choose the end datel"
                      format="MM/dd/yyyy"
                      value={details?.selectedEndDate}
                      inputVariant="outlined"
                      // onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  )}
                </MuiPickersUtilsProvider>
              </FormControl>
            </Box>
            <Box my={3}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Country
                </InputLabel>
                <Select
                  // labelId="demo-simple-select-outlined-label"
                  // id="demo-simple-select-outlined"
                  disabled
                  value={details?.country}
                  // onChange={(e) => setCountry(e.target.value)}
                  fullWidth
                  label="Country"
                >
                  {countriesArray?.map((countryItem) => (
                    <MenuItem value={countryItem?.value}>
                      {countryItem?.value === '' ? (
                        <em>{countryItem?.label}</em>
                      ) : (
                        countryItem?.label
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box my={3}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3,auto)'
              }}
            >
              <div style={{ paddingRight: '3px' }}>
                <Button
                  // color="default"
                  disabled={false}
                  fullWidth
                  size="medium"
                  // type="submit"
                  variant="contained"
                  onClick={handleBack}
                >
                  Back
                </Button>
              </div>
              <div style={{ width: '30%' }} />
              <div style={{ paddingLeft: '3px' }}>
                <Button
                  color="primary"
                  // disabled={errorNumberCount}
                  // disableRipple={errorNumberCount}
                  fullWidth
                  size="medium"
                  // type="submit"
                  onClick={() => handleSubmit(selectedNumbers, details)}
                  variant="contained"
                >
                  Submit
                </Button>
              </div>
            </div>
          </Box>
          {/* </form>
              )} */}
          {/* </Formik> */}
        </Container>
      </Box>
    </Page>
  );
};
PreviewEntry.propTypes = {
  handleSubmit: PropTypes.func,
  handleBack: PropTypes.func,
  details: PropTypes.object,
  selectedNumbers: PropTypes.array
};
export default PreviewEntry;
