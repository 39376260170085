import React from 'react';

const Logo = (props) => {
  return (
    <div
      style={{
        background: '#fff',
        borderRadius: '50%',
        width: '50px',
        height: '50px',
        padding: '3px'
      }}
    >
      <img alt="Logo" src="/static/logo.svg" {...props} />
    </div>
  );
};

export default Logo;
