import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Container, Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import styled from 'styled-components';
import theme from 'src/theme';
import { Alert } from '@material-ui/lab';

export const Round = styled.span`
    position: relative;
    display: inline-block;
    border-radius: 50%;
    margin: 2px;
    cursor: pointer;
    border: 1px solid ${theme.palette.primary.main} ;
    text-align: center;
    outline: none;
    height: 60px;
    width: 60px;
    line-height: 63px;
    color: #000080;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-size: 22px;
    flex: 1 100%;
    user-select: none;
    box-sizing: border-box;
    animation: .4s ease-in-out both text;
    -webkit-tap-highlight-color: transparent;
}`;

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

class LottoNumberSelector extends React.Component {
  constructor(props) {
    super(props);
    const { selectedNumbers } = this.props;
    this.state = {
      numberArray: [...range(1, 60)],
      selectedNumbers: selectedNumbers ?? [],
      errorSelection: false,
      errorNumberCount: false
    };
  }

  handleSubmitPool = () => {
    const { errorSelection, errorNumberCount, selectedNumbers } = this.state;
    const { handleNextNumberPool } = this.props;
    if (selectedNumbers?.length < 6) {
      this.setState({
        errorNumberCount: true
      });
    } else if (!(errorSelection && errorNumberCount)) {
      console.log('Submitted');
      handleNextNumberPool(selectedNumbers);
    }
  };

  clearSelection = () => {
    this.setState({
      selectedNumbers: [],
      errorSelection: false,
      errorNumberCount: false
    });
  };

  handleNext = () => {
    console.log('next');
    const { selectedNumbers } = this.state;
    this.setState(
      (previousState) => ({
        ...previousState,
        errorNumberCount: selectedNumbers?.length < 6
      }),
      () => this.handleSubmitPool()
    );
  };

  onClickNumber = (n) => {
    this.setState({ errorNumberCount: false });
    const { selectedNumbers } = this.state;

    if (selectedNumbers?.length >= 6) {
      this.setState({
        errorSelection: true
      });
      if (selectedNumbers?.includes(n)) {
        this.setState(
          {
            selectedNumbers: selectedNumbers?.includes(n)
              ? [...selectedNumbers?.filter((item) => item !== n)]
              : [...selectedNumbers, n]
          },
          () => this.setState({ errorSelection: selectedNumbers?.length > 6 })
        );
      }
    } else {
      this.setState(
        {
          selectedNumbers: selectedNumbers?.includes(n)
            ? [...selectedNumbers?.filter((item) => item !== n)]
            : [...selectedNumbers, n]
        },
        () => this.setState({ errorSelection: selectedNumbers?.length > 6 })
      );
    }
  };

  NumberPool = () => {
    const { numberArray, selectedNumbers } = this.state;

    return (
      <Box>
        {numberArray.map((e) => {
          return (
            <div style={{ display: 'inline-block' }}>
              <Round
                style={{
                  // borderColor: errorSelection ? 'grey' : 'inherit'
                  backgroundColor: selectedNumbers?.includes(e)
                    ? theme.palette.primary.main
                    : 'inherit',
                  color: selectedNumbers?.includes(e)
                    ? theme.palette.background.default
                    : theme.palette.text.secondary
                }}
                key={e}
                onClick={() => this.onClickNumber(e)}
              >
                {e}
              </Round>
            </div>
          );
        })}
      </Box>
    );
  };

  render() {
    const { errorSelection, errorNumberCount } = this.state;

    return (
      <Page
        style={{
          backgroundColor: theme.palette.background.dark,
          height: '100%',
          paddingBottom: theme.spacing(3),
          paddingTop: theme.spacing(3)
        }}
        title="Pick your numbers | CrowdAfrik Investments"
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Box mb={3}>
              <Typography color="textPrimary" variant="h3">
                Select your numbers
              </Typography>
            </Box>
            <Box mb={5}>{this.NumberPool}</Box>
            <span style={{ padding: '10px' }} />
            {errorSelection && (
              <Alert severity="error">
                You cannot select more than 6 numbers!
              </Alert>
            )}
            {errorNumberCount && (
              <Alert severity="warning">
                You have to select atleast 6 numbers to continue.
              </Alert>
            )}
            <Box my={3}>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3,auto)'
                }}
              >
                <div style={{ paddingRight: '3px' }}>
                  <Button
                    // color="default"
                    disabled
                    fullWidth
                    size="medium"
                    // type="submit"
                    variant="contained"
                    // onClick={this.clearSelection}
                  >
                    Back
                  </Button>
                </div>
                <div style={{ paddingRight: '3px' }}>
                  <Button
                    color="default"
                    // disabled={isSubmitting}
                    fullWidth
                    size="medium"
                    // type="submit"
                    variant="contained"
                    onClick={this.clearSelection}
                  >
                    Reset
                  </Button>
                </div>
                <div style={{ paddingLeft: '3px' }}>
                  <Button
                    color={errorNumberCount ? 'default' : 'primary'}
                    disabled={errorNumberCount}
                    disableRipple={errorNumberCount}
                    fullWidth
                    size="medium"
                    // type="submit"
                    onClick={() => this.handleNext()}
                    variant="contained"
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Box>
            {/* </form>
              )} */}
            {/* </Formik> */}
          </Container>
        </Box>
      </Page>
    );
  }
}
LottoNumberSelector.propTypes = {
  handleNextNumberPool: PropTypes.func,
  // handleBack: PropTypes.func,
  selectedNumbers: PropTypes.array
};
export default LottoNumberSelector;
