import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import { Container } from '@material-ui/core';
import Page from 'src/components/Page';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import config from 'src/config';
import LottoNumberSelector from './LottoNumberSelectorView';
import EnterDetailsView from './EnterDetailsView';
import PreviewEntry from './PreviewEntry';
import QRCodeGenerator from './QRCodeGenerator';

const styles = (theme) => ({
  root: {
    width: '90%'
  },
  button: {
    marginRight: theme.spacing
  },
  instructions: {
    marginTop: theme.spacing,
    marginBottom: theme.spacing
  }
});

function getSteps() {
  return ['Pick your numbers', 'Enter your details', 'Submit'];
}

class CrowdAfrikBonanza extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      skipped: new Set(),
      selectedNumbers: [],
      details: null,
      successSubmit: false,
      uniqueId: '',
      isLoading: false
    };
  }

  setSnackBarOpen = () => {
    console.log('snack bar');
    this.setState({ successSubmit: true, activeStep: 3, isLoading: false });
  };

  setSnackBarClose = () => {
    this.setState({ successSubmit: false });
  };

  handleNextDetails = (details) => {
    this.setState(
      {
        details
      },
      () => this.handleNext()
    );
  };

  getStepContent = (step) => {
    const { selectedNumbers, details } = this.state;
    switch (step) {
      case 0:
        return (
          <LottoNumberSelector
            handleNextNumberPool={this.handleNextNumberPool}
            // handleBack={this.handleBack}
            selectedNumbers={selectedNumbers}
          />
        );
      case 1:
        return (
          <EnterDetailsView
            handleBack={this.handleBack}
            handleNextDetails={this.handleNextDetails}
            details={details}
          />
        );
      case 2:
        return (
          <PreviewEntry
            details={details}
            handleBack={this.handleBack}
            selectedNumbers={selectedNumbers}
            handleSubmit={this.handleSubmit}
          />
        );
      // case 3:
      //   return <QRCodeGenerator responseID={uniqueId} isLoading={isLoading} />;
      default:
        return <LottoNumberSelector />;
    }
  };

  isStepOptional = (step) => step === 5;

  handleNextNumberPool = (selectedNumbersList) => {
    // const { selectedNumbers } = this.state;
    this.setState(
      {
        selectedNumbers: selectedNumbersList
      },
      () => this.handleNext()
    );
  };

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      skipped
    });
  };

  handleBack = () => {
    this.setState((state) => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    const { selectedNumbers } = this.state;

    if (!this.isStepOptional(activeStep) && selectedNumbers) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState((state) => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped
      };
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  snackBar = () => {
    const { successSubmit } = this.state;
    return (
      <Snackbar
        open={successSubmit}
        autoHideDuration={5000}
        onClose={this.setSnackBarClose}
      >
        <Alert onClose={this.setSnackBarClose} severity="success">
          Thankyou! We have recieved your entry!
        </Alert>
      </Snackbar>
    );
  };

  setUniqueID = (uniqueId) => {
    this.setState(
      {
        uniqueId
      },
      () => this.setSnackBarOpen()
    );
  };

  handleSubmit = (selectedNumbers, details) => {
    // event.preventDefault();
    this.setState({
      isLoading: true
    });
    const entry = {
      game: 'Beta Lottery',
      entry: selectedNumbers?.toString(),
      name: details?.name,
      mobile_number: details?.tel,
      email: details?.email,
      country: details?.country,
      country_code: 123,
      game_start: details?.selectedStartDate,
      isRepeating: details?.isRepeating,
      game_end: details?.isRepeating
        ? details?.selectedEndDate
        : details?.selectedStartDate
    };

    console.log('entry -> ', entry);
    axios
      .post(`${config.API_URL}/apis/enter/`, entry, {
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      })
      .then((res) => {
        const uniqueId = res?.data?.unique_id;
        console.log(uniqueId);
        this.setUniqueID(uniqueId);
      })
      .catch((error) => {
        console.log('Error =>', error);
      });
  };

  isStepSkipped(step) {
    const { skipped } = this.state;
    return skipped.has(step);
  }

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const {
      activeStep, successSubmit, uniqueId, isLoading
    } = this.state;

    return (
      <Page title="CrowdAfrik Investments">
        <div>
          <Container maxWidth="sm">
            <div className={classes.root}>
              <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                  const props = {};
                  const labelProps = {};
                  if (this.isStepOptional(index)) {
                    labelProps.optional = (
                      <Typography variant="caption">Optional</Typography>
                    );
                  }
                  if (this.isStepSkipped(index)) {
                    props.completed = false;
                  }
                  return (
                    <Step key={label} {...props}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
              <div>
                {activeStep === steps.length ? (
                  <QRCodeGenerator
                    responseID={uniqueId}
                    isLoading={isLoading}
                  />
                ) : (
                  <div style={{ margin: '0 auto' }}>
                    <Typography className={classes.instructions}>
                      {this.getStepContent(activeStep)}
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </Container>
          {successSubmit && this.snackBar()}
        </div>
      </Page>
    );
  }
}

CrowdAfrikBonanza.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(CrowdAfrikBonanza);
