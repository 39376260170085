import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@material-ui/lab';
import {
  makeStyles,
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import MuiPhoneNumber from 'material-ui-phone-number';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import 'date-fns';
import theme from 'src/theme';

const useStyles = makeStyles((Themes) => ({
  root: {
    backgroundColor: Themes.palette.background.dark,
    height: '100%',
    paddingBottom: Themes.spacing(3),
    paddingTop: Themes.spacing(3)
  }
}));

export const countriesArray = [
  {
    label: 'None',
    value: ''
  },
  {
    label: 'Ghana',
    value: 'Ghana'
  },
  {
    label: 'Nigeria',
    value: 'Nigeria'
  },
  {
    label: 'Tanzania',
    value: 'Tanzania'
  },
  {
    label: 'Liberia',
    value: 'Liberia'
  },
  {
    label: 'South Africa',
    value: 'South Africa'
  }
];

const EnterDetailsView = (props) => {
  const { details } = props;

  const classes = useStyles();
  const [name, setName] = useState(details?.name ?? '');
  const [email, setEmail] = useState(details?.email ?? '');
  const [password, setPassword] = useState(details?.password ?? '');
  const [tel, setTel] = useState(details?.tel ?? '');
  const [country, setCountry] = useState(details?.country ?? '');
  const [isEmpty, setEmpty] = useState(details?.isEmpty ?? false);
  const [isRepeating, setRepeat] = useState(details?.isRepeating ?? false);

  const [selectedStartDate, setStartSelectedDate] = React.useState(
    new Date(details?.selectedStartDate ?? '2021-02-18T21:11:54')
  );
  const [selectedEndDate, setEndSelectedDate] = React.useState(
    new Date(details?.selectedEndDate ?? '2021-02-18T21:11:54')
  );

  const handleStartDateChange = (date) => {
    setStartSelectedDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndSelectedDate(date);
  };

  const handleReset = () => {
    setName('');
    setEmail('');
    setPassword('');
    setTel('');
    setCountry('');
    setEmpty(false);
    setRepeat(false);
    setStartSelectedDate(new Date('2021-02-18T21:11:54'));
    selectedEndDate(new Date('2021-02-18T21:11:54'));
  };

  const handleNext = () => {
    const { handleNextDetails } = props;
    const newDetails = {
      name,
      tel,
      password,
      country,
      email,
      selectedStartDate,
      isRepeating,
      selectedEndDate
    };

    if (
      !name
      || !tel
      || !password
      || !country
      || !email
      // || selectedStartDate !== new Date('2021-02-18T21:11:54')
      // || (selectedEndDate !== new Date('2021-02-18T21:11:54') && isRepeating)
    ) {
      setEmpty(true);
    } else {
      handleNextDetails(newDetails);
    }
  };
  const { handleBack } = props;

  return (
    <Page
      className={classes.root}
      title="Enter your details | CrowdAfrik Investments"
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Typography color="textPrimary" variant="h3">
              Enter your details
            </Typography>
          </Box>
          <Box mb={3}>
            <TextField
              // error={Boolean(touched.email && errors.email)}
              fullWidth
              // helperText={touched.email && errors.email}
              label="Name"
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
              type="name"
              variant="outlined"
            />
            <TextField
              // error={Boolean(touched.email && errors.email)}
              fullWidth
              // helperText={touched.email && errors.email}
              label="Email Address"
              margin="normal"
              name="email"
              // onBlur={handleBlur}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              value={email}
              variant="outlined"
            />
            <TextField
              // error={Boolean(touched.password && errors.password)}
              fullWidth
              // helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              // onBlur={handleBlur}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              variant="outlined"
            />
            <MuiPhoneNumber
              fullWidth
              label="Telephone with extension code"
              margin="normal"
              name="tel"
              value={tel}
              variant="outlined"
              defaultCountry="gb"
              onChange={(e) => setTel(e)}
            />
            <Box>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Choose the start date"
                    format="MM/dd/yyyy"
                    value={selectedStartDate}
                    inputVariant="outlined"
                    onChange={handleStartDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={isRepeating}
                        onChange={() => {
                          setRepeat(!isRepeating);
                        }}
                        name="checkedBox"
                        color={theme.palette.primary.main}
                      />
                    )}
                    label="Repeat these until custom weeks"
                  />
                  {isRepeating && (
                    <KeyboardDatePicker
                      margin="normal"
                      id="date-picker-dialog"
                      label="Choose the end datel"
                      format="MM/dd/yyyy"
                      value={selectedEndDate}
                      inputVariant="outlined"
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                    />
                  )}
                </MuiPickersUtilsProvider>
              </FormControl>
            </Box>
            <Box my={3}>
              <FormControl
                fullWidth
                variant="outlined"
                className={classes.formControl}
              >
                <InputLabel id="demo-simple-select-outlined-label">
                  Country
                </InputLabel>
                <Select
                  // labelId="demo-simple-select-outlined-label"
                  // id="demo-simple-select-outlined"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  fullWidth
                  label="Country"
                >
                  {countriesArray?.map((countryItem) => (
                    <MenuItem value={countryItem?.value}>
                      {countryItem?.value === '' ? (
                        <em>{countryItem?.label}</em>
                      ) : (
                        countryItem?.label
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>

          {isEmpty && (
            <Alert severity="error">You cannot leave the fields empty.</Alert>
          )}
          <Box my={3}>
            <div
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3,auto)'
              }}
            >
              <div style={{ paddingRight: '3px' }}>
                <Button
                  // color="default"
                  disabled={false}
                  fullWidth
                  size="medium"
                  variant="contained"
                  onClick={handleBack}
                >
                  Back
                </Button>
              </div>
              <div style={{ paddingRight: '3px' }}>
                <Button
                  color="default"
                  // disabled={isSubmitting}
                  fullWidth
                  size="medium"
                  // type="submit"
                  variant="contained"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </div>
              <div style={{ paddingLeft: '3px' }}>
                <Button
                  color="primary"
                  // disabled={errorNumberCount}
                  // disableRipple={errorNumberCount}
                  fullWidth
                  size="medium"
                  // type="submit"
                  onClick={() => handleNext()}
                  variant="contained"
                >
                  Next
                </Button>
              </div>
            </div>
          </Box>
          {/* </form>
              )} */}
          {/* </Formik> */}
        </Container>
      </Box>
    </Page>
  );
};
EnterDetailsView.propTypes = {
  handleNextDetails: PropTypes.func,
  handleBack: PropTypes.func,
  details: PropTypes.object
  // selectedNumbers: PropTypes.array
};
export default EnterDetailsView;
