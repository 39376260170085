import React, { useState } from 'react';
import {
  Box, Container, Grid, makeStyles
} from '@material-ui/core';
// import { Pagination } from '@material-ui/lab';
import Page from 'src/components/Page';
import { useNavigate } from 'react-router-dom';
import ProductCard from './ProductCard';
import data from './data';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%',
    cursor: 'pointer'
  }
}));

const ProductList = () => {
  const classes = useStyles();
  const [products] = useState(data);
  const navigate = useNavigate();

  return (
    <Page className={classes.root} title="Products">
      <Container maxWidth="lg">
        <Box mt={3}>
          <Grid container spacing={3}>
            {products.map((product) => (
              <Grid item key={product.id} lg={4} md={6} xs={12}>
                <ProductCard
                  className={classes.productCard}
                  product={product}
                  onClick={() => {
                    navigate(product.url, { replace: true });
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
        {/* <Box
          mt={3}
          display="flex"
          justifyContent="center"
        >
          <Pagination
            color="primary"
            count={3}
            size="small"
          />
        </Box> */}
      </Container>
    </Page>
  );
};

export default ProductList;
